var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("read", "users")
    ? _c(
        "div",
        [
          _vm.userData
            ? _c(
                "b-row",
                { staticClass: "content-header" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "content-header-left mb-2",
                      attrs: { cols: "12", md: "9" }
                    },
                    [
                      _c(
                        "b-row",
                        { staticClass: "breadcrumbs-top" },
                        [
                          _c("b-col", { attrs: { cols: "12" } }, [
                            _c(
                              "h2",
                              {
                                staticClass:
                                  "content-header-title float-left pr-1 mb-0"
                              },
                              [_vm._v(" Users ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "breadcrumb-wrapper" },
                              [
                                _c(
                                  "b-breadcrumb",
                                  [
                                    _c(
                                      "b-breadcrumb-item",
                                      { attrs: { to: "/apps/reports/type" } },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "align-text-top",
                                          attrs: {
                                            icon: "HomeIcon",
                                            size: "16"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-breadcrumb-item",
                                      { attrs: { to: "/apps/users/list" } },
                                      [_vm._v(" Users ")]
                                    ),
                                    _c(
                                      "b-breadcrumb-item",
                                      { attrs: { active: "" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.userData.username) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-alert",
            { attrs: { variant: "danger", show: _vm.userData === undefined } },
            [
              _c("h4", { staticClass: "alert-heading" }, [
                _vm._v(" Error fetching user data ")
              ]),
              _c(
                "div",
                { staticClass: "alert-body" },
                [
                  _vm._v(" No user found with this username. Check "),
                  _c(
                    "b-link",
                    {
                      staticClass: "alert-link",
                      attrs: { to: { name: "apps-users-list" } }
                    },
                    [_vm._v(" User List ")]
                  ),
                  _vm._v(" . ")
                ],
                1
              )
            ]
          ),
          _vm.userData
            ? [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "24", xl: "12", lg: "12", md: "12" } },
                      [
                        _c("user-view-user-info-card", {
                          attrs: { "user-data": _vm.userData }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "24", xl: "12", lg: "12", md: "12" } },
                      [
                        _c("user-view-user-permissions-card", {
                          attrs: { "user-data": _vm.userData }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }